@tailwind base;
@tailwind components;
@tailwind utilities;

  @font-face {
    font-family: 'ShipporiMincho';
    src:  url('/public/fonts/ShipporiMincho-ExtraBold.ttf') ;
}

  .font-shippo{
    font-family: ShipporiMincho;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #D6E7FE;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #1976D2;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #56a7f8;
  }

  .MuiPaper-root{
    box-shadow: none !important;
    border-radius: 0 ;
  }